import React, { useState } from "react";
import GameClient, { ClientPile, genCommand, ClientCharacter } from "./GameClient";
import { allAreaNames, AllAreas, Message, MessageContent } from "./types";

type isShownType = Record<AllAreas, boolean>;

function HideWrapper({ children, txt = "hide/show" }: React.PropsWithChildren<{ txt?: string }>) {
  const [shown, setShown] = useState<boolean>(false);
  return (
    <>
      <button type="button" onClick={() => setShown(prev => !prev)}>{txt}</button>
      {
        shown &&
        children
      }
    </>
  );
}

function ClientDisplay({ children, gameClient, ws }: React.PropsWithChildren<{ gameClient: GameClient, ws: React.MutableRefObject<WebSocket | null> }>) {
  const [isShown, setIsShown] = useState<isShownType>(() => {
    const areas = {} as isShownType;
    for (const area of allAreaNames) {
      areas[area] = true;
    }
    return areas;
  });

  const toggleShown = (name: AllAreas) => {
    setIsShown(prev => {
      const cloned = structuredClone(prev) as isShownType;
      cloned[name] = !cloned[name];
      return cloned;
    });
  }

  const descript = (discription: string) => {
    const descriptionArray = discription.split(',')
    return descriptionArray.map((d) => (
      <div>
        {d}
      </div>
    ))
  }

  const genPile = (pile: ClientPile) => (
    <>
      <p>
        {pile.name}
        <button type="button" onClick={() => toggleShown(pile.name)}>hide/show</button>
        {
          isShown[pile.name] &&
          <span>
            {pile.options.includes("scry") && pile.name !== "door" &&
              <button key="scry" type="button" onClick={() => {
                let msg = genCommand(pile, "scry", 1);
                console.log(msg);
                ws.current!.send(JSON.stringify(msg!));
              }}>scry</button>
            }
          </span>
        }
      </p>
      {
        isShown[pile.name] &&
        <ol start={0}>
          {pile.cards.map((c, cardIdx) => (
            <li key={cardIdx.toString()}>
              {pile.options.map((opt, optIdx) => (
                (opt !== "scry" || pile.name === "door") &&
                <button key={optIdx.toString()} type="button" onClick={() => {
                  let msg = genCommand(pile, opt, cardIdx);
                  console.log(msg);
                  ws.current!.send(JSON.stringify(msg!));
                }}>{opt}</button>
              ))}
              <>{descript(c)}</>
            </li>
          ))}
        </ol>
      }
    </>
  );


  const characterDisplay = (character: ClientCharacter) => {
    return (
      <div>
        {character.name}: {character.attri}
        <HideWrapper txt="description">
          {descript(character.description)}
        </HideWrapper>
        <div>
          HP: {character.health}
          {
            // idx + 1 === gameClient.selfId &&
            <button type="button" onClick={() => {
              let msg = {
                target: 0,
                msg: {
                  msgType: "command",
                  command: `increase health ${1}`
                } as MessageContent
              } as Message;
              console.log(msg);
              ws.current!.send(JSON.stringify(msg!));
            }}>increase</button>

          }
          {
            // idx + 1 === gameClient.selfId &&
            <button type="button" onClick={() => {
              let msg = {
                target: 0,
                msg: {
                  msgType: "command",
                  command: `increase health ${-1}`
                } as MessageContent
              } as Message;
              console.log(msg);
              ws.current!.send(JSON.stringify(msg!));
            }}>decrease</button>
          }
        </div>
        <HideWrapper txt="info">
          {
            // idx + 1 !== gameClient.selfId &&
            character.piles.map((pile, pileIdx) => (
              <div key={pileIdx.toString()}>
                {genPile(pile)}
              </div>
            ))
          }
        </HideWrapper>
      </div>
    );
  }


  // TODO: draw this
  return (
    <div className="container">
      {/* <div className="abs_container"> */}
      <header className="App-header">
      </header>
      <div className='left'>
        <div className='top'>
          {gameClient.characters.length !== 0 && characterDisplay(gameClient.characters[gameClient.selfId - 1]) || gameClient.selfId}
        </div>
        {children}
      </div>
      <div className='mid'>
        <h3>piles</h3>
        <div>
          {(gameClient.characters.length !== 0 && gameClient.piles.map((pile, pileIdx) => (
            <div key={pileIdx.toString()}>
              {genPile(pile)}
            </div>
          ))) ||
            gameClient.allCharacters.map((character, idx) => (
              <p>{idx}:   {character}</p>
            ))}
        </div>
      </div>
      <div className="right"><>
        <h3>characters</h3>
        <div>
          <ol>
            {gameClient.characters.map((character, idx) => (
              <>{idx + 1 !== gameClient.selfId && characterDisplay(character)}</>
            ))}
          </ol>
        </div>


      </></div>
    </div >
  );
}

export default ClientDisplay;
