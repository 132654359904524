import { useEffect, useState, useRef } from 'react';
import ClientDisplay from './ClientDisplay';
import GameClient, { receiveMsg } from './GameClient';
import GameServer from './GameServer';
import ServerDisplay from './ServerDisplay';
import { Message } from './types';
import './flex.css'
// import './absolute.css'

function LogDisplay({ logList }: { logList: string[] }) {
  const pageEnd = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => { pageEnd.current!.scrollIntoView(); }, 100);
  }, [logList])
  return (
    <div className='bottom'>
      {logList.map((text, idx) => (
        <p key={idx.toString()}>{text}</p>))}
      <div ref={pageEnd}></div></div>)
}

export default function App() {
  const [logList, setLogList] = useState<Array<string>>(["starting"]);
  const gameServer = useRef<GameServer>(new GameServer());
  const clientId = useRef<number>(-1);
  const [gameClient, setGameClient] = useState<GameClient>(new GameClient(-1));
  const ws = useRef<WebSocket | null>(null);
  const [reconnect, setReconnect] = useState<number>(0);
  const [, rerender] = useState<number>(0);

  useEffect(() => {
    ws.current = new WebSocket(`${process.env.REACT_APP_WS}/ws/join`);
    ws.current.addEventListener("close", ev => {
      setLogList(prevList => [...prevList, `WebSocket Disconnected code: ${ev.code}, reason: ${ev.reason}`]);

      if (ev.code !== 1001) {
        setLogList(prevList => [...prevList, `Reconnecting in 1s`]);
      }
    });
    ws.current.addEventListener("open", _ => {
      setLogList(prevList => [...prevList, `websocket connected`]);
      console.info("websocket connected")
    });
    ws.current.addEventListener("message", ev => {
      if (typeof ev.data !== "string") {
        console.error("unexpected message type", typeof ev.data)
        return;
      }
      console.log(`receive raw data ${ev.data}`);
      let d = JSON.parse(ev.data) as Message;
      if (clientId.current < 0) {
        // first message
        if (d.target >= 0) {
          clientId.current = d.target;
        } else {
          console.error("wrong id");
        }
        if (clientId.current === 0) {
          // initialize server, actually do nothing here
        }
      } else if (clientId.current === 0) {
        // for server
        let messages = gameServer.current.receiveMsg(d);
        for (let msg of messages) {
          ws.current!.send(JSON.stringify(msg));
        }
        return;
      } else if (d.msg.msgType === "control") {
        if (d.msg.control === "start") {
          setGameClient(new GameClient(clientId.current));
        }
      } else if (d.msg.msgType === "info") {
        setLogList(prevList => [...prevList, d.msg.info]);
      } else {
        setGameClient(gameClient => receiveMsg(d.msg, gameClient));
      }
    });
  }, [reconnect]);

  return (
    // <div className="App">
    <>{(
      clientId.current >= 0 && (
        (clientId.current > 0 &&
          <ClientDisplay gameClient={gameClient} ws={ws}>
            <LogDisplay logList={logList} />
          </ClientDisplay>) || (
          <ServerDisplay gameServer={gameServer} ws={ws}>
            <LogDisplay logList={logList} />
          </ServerDisplay>)
      )
    ) ||
      <button type="button" onClick={() => { rerender(prev => prev + 1) }}>re-render</button>
    }</>
  )
}
