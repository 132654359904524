export const doorNames = ["The other", "The night", "The star", "The twilight", "The sun"] as const;

export type Options = "draw" | "scry" | "pick" | "discard" | "use" | "tap" | "flip" | "increase";

export type Statuses = "health";

export const publicAreaNames = ['derivative', 'rot', 'action', 'quest', 'battlefield', 'discarded', 'door'] as const;

export const privateAreaNames = ["hand", "indivRot", "indivQuest", "indivWeakness"] as const;

export type PrivateAreas = typeof privateAreaNames[number];

export const allAreaNames = [...privateAreaNames, ...publicAreaNames] as const;

export type AllAreas = typeof allAreaNames[number];

export const cardBackVisibility: Record<AllAreas, boolean> = {
  derivative: true,
  discarded: true,
  battlefield: true,
  indivQuest: true,

  rot: false,
  action: false,
  quest: false,
  hand: false,
  indivRot: false,
  indivWeakness: false,
  door: false,
};

export const pileIsStacked: Record<AllAreas, boolean> = {
  rot: true,
  action: true,
  quest: true,

  derivative: false,
  discarded: false,
  battlefield: false,
  hand: false,
  indivQuest: false,
  indivRot: false,
  indivWeakness: false,
  door: false,
};

export const everyone = -1;

export type MessageContent = {
  msgType:
  "command" | "control" | "info" |
  "pileCards" | "charactersInit" | "characterStatus" | "characterPile",
  command: string,
  control: "start" | "empty",
  info: string,
  pileCards: {
    pile: AllAreas,
    cards: string[],
  },
  characterInit: {
    id: number,
    name: string,
    description: string,
    health: number,
  }[],
  characterStatus: {
    id: number,
    name: "health",
    value: number,
  },
  characterPile: {
    id: number,
    pile: typeof privateAreaNames[number],
    cards: string[],
  }
}

export type Message = {
  target: number,
  source: number,
  msg: MessageContent,
}
