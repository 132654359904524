import { AllAreas, Message, MessageContent, Options } from './types';
import cardData from "./cards/cards";

type ClientCard = string;

export class ClientPile {
  name: AllAreas;
  options: Options[];
  cards: ClientCard[] = [];
  constructor(name: AllAreas, options: Options[]) {
    this.name = name;
    this.options = options;
  }
  currentMethod: Options = "draw";
}

export const genCommand = (pile: ClientPile, option: Options, idx: number): Message | undefined => {
  // should not be a method of pile, for react reason
  // return the msg to be sent to the game server
  if (!pile.options.includes(option)) {
    return;
  }
  // client does not care about this, and for client some piles have len=1
  // if (idx < 0 || idx > pile.cards.length) {
  //   return;
  // }
  return {
    target: 0,
    msg: {
      msgType: "command",
      command: `${option} ${pile.name} ${idx}`
    } as MessageContent
  } as Message;

}

export class ClientCharacter {
  // fixed
  id: number;
  name: string;
  description: string;
  attri: string;
  // modifiable
  health: number;

  piles = [
    new ClientPile("hand", ["scry"]),
    new ClientPile("indivRot", []),
    new ClientPile("indivQuest", []),
    new ClientPile("indivWeakness", ["scry"]),
  ];

  constructor(id: number, name: string, description: string, health: number) {
    this.id = id;
    this.name = name;
    this.description = description.split(",").slice(4).join(",");
    this.health = health;
    this.attri = description.split(",").slice(0, 4).join(",");
  }
}

export default class GameClient {
  selfId = 0;
  // piles should be the same as clientAreas defined in types
  piles = [
    new ClientPile("action", ["draw", "scry"]),
    new ClientPile("rot", ["draw", "scry"]),
    new ClientPile("quest", ["draw"]),
    new ClientPile("derivative", ["pick"]),
    new ClientPile("battlefield", ["discard"]),
    new ClientPile("discarded", []),
    new ClientPile("hand", ["use", "discard"]),
    new ClientPile("indivRot", ["flip", "discard"]),
    new ClientPile("indivQuest", ["tap", "discard"]),
    new ClientPile("indivWeakness", ["use"]),
    new ClientPile("door", ["scry"])
  ];
  characters: ClientCharacter[] = [];
  allCharacters = cardData.hero;
  constructor(id: number) {
    this.selfId = id;
  }
}

export const receiveMsg = (msg: MessageContent, prev: GameClient): GameClient => {
  // should not be a method of GameClient, as GameClient will be a state of react
  console.log(`game client recv ${msg}`);
  const cloned = structuredClone(prev) as GameClient;
  switch (msg.msgType) {
    case "pileCards": {
      for (const p of cloned.piles) {
        if (p.name === msg.pileCards.pile) {
          p.cards = msg.pileCards.cards;
          break;
        }
      }
      break;
    }
    case "charactersInit": {
      cloned.characters = msg.characterInit.map((c) => (new ClientCharacter(c.id, c.name, c.description, c.health)));
      break;
    }
    case "characterStatus": {
      for (const c of cloned.characters) {
        if (c.id === msg.characterStatus.id) {
          // change to c.statuses[name] for adding more statuses
          if (msg.characterStatus.name === "health")
            c.health = msg.characterStatus.value;
        }
      }
      break;
    }
    case "characterPile": {
      if (msg.characterPile.id !== prev.selfId) {
        for (const c of cloned.characters) {
          if (c.id === msg.characterPile.id) {
            for (const p of c.piles) {
              if (p.name === msg.characterPile.pile) {
                p.cards = msg.characterPile.cards;
                break;
              }
            }
            break;
          }
        }
      }
      break;
    }
  }
  return cloned;
}
