const categories = ['action', 'hero', 'rot', 'quest', 'derivative'] as const;
type cardCategories = Record<typeof categories[number], string[]>;
const cardData: cardCategories = {
  "action": [
    "攻击：劈刺,体力: 攻击+1,4,,",
    "防御：格挡,无法防御射击。体力：防御+1,4,,",
    "攻击：射击,意志：攻击+1,4,,",
    "防御：躲避,防御射击和飞弹时防御+1。,4,,",
    "攻击：触摸,智力：造成等于智力的伤害。,4,,",
    "防御：护盾,防御射击和劈刺时防御+1,4,,",
    "攻击：飞弹,智力: 攻击+1,4,,",
    "防御：闪现,智力: 防御+1。无法防御射击,4,,",
    "沟通：求医,不能在战斗中打出，除非濒死。恢复1点生命值,4,,",
    "沟通：开导,查看对方手牌，并弃置N张。N=魅力,4,,",
    "沟通：表演,阻止一张观察。魅力：无法被阻止。,4,,",
    "沟通：夜宴,选择N人与你相互展示弱点并各恢复1点体力。N=魅力,4,,",
    "沟通：诡计,不能在战斗中打出。获得钥匙，或抽N张牌，N=魅力,4,,",
    "观察：鹰眼,下一张攻击加效，意志: 无视隐匿。,4,,",
    "观察：勘破,阻止一张沟通。意志：使本回合下次攻击伤害+1,4,,",
    "观察：入微,当弱点或门被查看时打出：你也可以查看，无需理智检定。意志：你可以选择是否接受理智检定。,4,,",
  ],
  "hero": [
    "孙澈,2,0,2,1,【躲避：隐匿】只能在非战斗回合使用。不受劈刺、触摸与射击，直至打出下一张牌或你的下个回合结束。,魔刺客：脱离隐匿的回合，抽两张牌并获得【攻击：触摸】【防御：隐匿】,钝感：射击、飞弹、护盾失效。",
    "乔伊,1,2,0,1,【防御：好运】防御射击时防御+1,告死鸟：其它角色死亡与疯狂时交给你两张牌,流浪的黑鸦：不受来自任务的任何影响",
    "云和安,0,2,1,1,【沟通：权威】阻止一张沟通牌，用于任务效果+1,生而有翼：修整中只受加效射击、加效飞弹的攻击，直至打出一张牌。,大地的诅咒：劈刺、触摸失效",
    "谢疏,2,0,2,0,【沟通：权威】阻止一张沟通牌，用于任务效果+1,为山千仞：修整中只受法术伤害，直至打出一张牌。,天空的诅咒：触摸、护盾、飞弹、闪现失效",
    "老东西,2,1,1,0,【观察：念写】查看对方手牌，并将其中一张加入自己手牌,隐者：回合结束时查看牌堆顶，若为观察则加入自己手牌,用处：无",
    "陈疯,1,1,0,2,【触摸：召唤】无法防御，无伤害，获得对方的钥匙,不死人：当其它持钥人濒死时，获得治疗1，并抽2张牌,求死心：当持钥人濒死时，进入濒死并为持钥人治疗1",
    "艾琳娜,0,1,2,1,【沟通：理解】使一张牌失效，抽一张牌。,追风人：每完成一个任务，治疗1,断章：有伤时无法攻击",
    "易舟,0,2,0,2,【飞弹：弹幕】攻击+1,行书阁：回合开始额外抽一张,书里没这个：护盾与飞弹以外所有攻击与防御卡牌失效",
    "安知,1,0,2,1,【攻击：惊鸿】可视为任意一张攻击（或非任何类型的攻击）。,白剑：在他人的回合可将任意攻击牌视为防御打出。,断章：有伤时无法攻击",
    "李念,1,1,1,0,【躲避：隐匿】只能在非战斗回合使用。不受劈刺、触摸与射击，直至打出下一张牌或你的下个回合结束。,被遗忘者：不受飞弹、夜话、开导。,神隐：所有攻击牌失效",
    "大范,3,0,1,0,【劈刺：涂毒】攻击+1，若成功，对方弃一张牌,送葬人：场上有人死亡时抽两张牌,黑檀棺木：游戏开始后无法攻击任何人，直至其疯狂或攻击你或你疯狂。",
    "贞驹,1,1,1,2,【夜宴：疗愈】不能在战斗中使用，治疗1,人心可畏：疗愈生效时可以改为造成伤害，对自己使用该效果时抽三张牌。,心中幻影：受到劈刺和触摸的伤害+1",
  ],
  "rot": [
    "通情达理,执行任务时魅力+1,与世界的联系,暗夜,选择一个人，你无法成为其他人沟通牌的目标。缺陷：也无法对其他人使用沟通牌,",
    "能说会道,使用沟通牌时魅力+1，最高为2,迷失自我,暗夜,你的沟通牌可以视为任意沟通牌打出。缺陷：失去你现在的优势与劣势,",
    "博学多闻,手牌上限+1,沧浪浊兮,暗夜,每当你在你的回合结束时弃牌，进入隐匿。缺陷：你的攻击失效,",
    "见微知著,你每回合第一次使用观察牌时抽一张牌,沧浪清兮,暗夜,你的攻击牌获得加效且不会被失效。缺陷：你的防御失效,",
    "坚忍不拔,你不受受伤状态和濒死状态的负面效果。,长夜趣味,暗夜,你可以对自己造成1点伤害来抽取2张牌。缺陷：生命<=2时无法使用。,",
    "内陆帝国,若回合内没有打出任何牌则无需弃牌,忘返,暗夜,回合结束时抽一张牌。缺陷：并弃一张牌,",
    "钢筋铁骨,生命+1,暴食,暗夜,【夜宴：疗愈】治疗1，可以在战斗中打出。缺陷：夜宴必须视为疗愈。,",
    "天人感应,回合开始时，查看行动牌堆顶牌，并选择是否将其丢弃。,真实灵魂,暗夜,你能随时查看牌堆顶的牌，若将其展示，使同名牌对你失效。缺陷：回合开始时少抽一张牌,",
    "通情达理,执行任务时魅力+1,傲慢,寒星,魅力+1。缺陷：无法对智力低于你的人使用观察,",
    "能说会道,使用沟通牌时魅力+1，最高为2,未来,寒星,【沟通：预言】将牌库顶的牌加入手牌；在战斗中可以立即打出。缺陷：智力-1，最小为0。,",
    "博学多闻,手牌上限+1,谦卑,寒星,每当其他人弃牌时，你获得其中的一张。缺陷：他人对你的沟通加效,",
    "见微知著,你每回合第一次使用观察牌时抽一张牌,真相,寒星,查看两扇门，然后放回。缺陷：你必须随机放回。,",
    "坚忍不拔,你不受受伤状态和濒死状态的负面效果。,救赎,寒星,意志比你低的人无法对你使用触摸和躲避。缺陷：意志比你高的人对你的攻击获得加效,",
    "内陆帝国,若回合内没有打出任何牌则无需弃牌,正义,寒星,移除一张非寒星的腐化。缺陷：不能对其它玩家使用。,",
    "钢筋铁骨,生命+1,肉体苦弱,寒星,【机械化】所有基础属性改为1。缺陷：无法被【沟通：求医】以外的方式治疗,",
    "天人感应,回合开始时，查看行动牌堆顶牌，并选择是否将其丢弃。,至高道德,寒星,智力+2。缺陷：无法对意志不为0的人使用沟通牌,",
    "通情达理,执行任务时魅力+1,使磨推鬼,暮光,【沟通：贿赂】完成一个任意分支。缺陷：需要弃一张牌,",
    "能说会道,使用沟通牌时魅力+1，最高为2,信口雌黄,暮光,你的沟通牌可以视为任意沟通牌打出。缺陷：无法对意志高于你的人如此做。,",
    "博学多闻,手牌上限+1,多多益善,暮光,手牌没有上限。缺陷：每当你弃牌时，受到一点伤害,",
    "见微知著,你每回合第一次使用观察牌时抽一张牌,气息感知,暮光,你在森林、山谷地形时，受到的伤害和弃牌效果-1。缺陷：你对亡灵、半神、机械的攻击防御失效。,",
    "坚忍不拔,你不受受伤状态和濒死状态的负面效果。,韬光养晦,暮光,【诡计：隐匿】本回合不能攻击。不受劈刺、触摸与射击，直至打出下一张牌或你的下个回合结束。缺陷：手牌>=4时退出隐匿,",
    "内陆帝国,若回合内没有打出任何牌则无需弃牌,超凡入圣,暮光,意志+1，你不受濒死影响，你对人类的攻击伤害翻倍。缺陷：你受到人类和亡灵的攻击伤害翻倍,",
    "钢筋铁骨,生命+1,披甲成圣,暮光,你的生命上限等于体力+4，你不受濒死影响。缺陷：体力-1，最低为0。,",
    "天人感应,回合开始时，查看行动牌堆顶牌，并选择是否将其丢弃。,回归本能,暮光,你在战斗中可以一次打出多张攻击或防御。缺陷：你在战斗中无法使用沟通和观察,",
    "通情达理,执行任务时魅力+1,同类嗅觉,赤日,每个人交出一张手牌，洗混后交给你。如果有赤日弱点，必须尽可能交出攻击牌；否则尽可能交出其他牌。缺陷：然后你立即弃牌至4张。,",
    "能说会道,使用沟通牌时魅力+1，最高为2,引诱,赤日,【沟通：引诱】完成一个人类任务的任意分支。缺陷：只能以此完成自己的任务。,",
    "博学多闻,手牌上限+1,魔力,赤日,智力+2。缺陷：手牌上限-1,",
    "见微知著,你每回合第一次使用观察牌时抽一张牌,专注,赤日,你的攻击牌获得加效且不会失效。缺陷：你无法对其它玩家使用观察牌,",
    "坚忍不拔,你不受受伤状态和濒死状态的负面效果。,一人之军,赤日,每使用一张攻击，抽一张牌，你对半神的攻击+1。缺陷：飞弹和射击除外。,",
    "内陆帝国,若回合内没有打出任何牌则无需弃牌,复仇,赤日,回合结束时，抽等于损失生命数量的牌。缺陷：回合开始时不抽牌,",
    "钢筋铁骨,生命+1,活下去,赤日,令一个人治疗2，直至其下个回合开始前他不会受伤。缺陷：只能对自己使用。,",
    "天人感应,回合开始时，查看行动牌堆顶牌，并选择是否将其丢弃。,成为恐惧,赤日,意志<=1的角色无法对你使用射击、触摸、飞弹。缺陷：意志大于等于你的角色对你的攻击加效,",
  ],
  "quest": [
    "森林：暗箭,事件-袭击：回合结束时若没有推进任务，受1点伤害,体力防御2,1：抽牌并展示直到抽到攻击牌为止,观察2,2：弃一张攻击牌，否则丢弃所有手牌,",
    "高塔：哨站,战斗-工事：抽一张任务，你可以保留作为下个任务，或丢弃。,智力攻击2,3：下个任务不会向左,防御1,4：下个任务不会向右,",
    "山谷：候援,战斗-人类：回合结束时若没有推进任务，受1点伤害,体力防御2,5：治疗1,攻击1,1：参与这个任务的所有人受伤1,",
    "巢穴：飞蜥,战斗-神孽：回合结束时进入隐匿，你脱离隐匿时受到1点伤害,触摸射击1,2：抽两张牌,触摸/射击1,3：弃两张牌,",
    "迷途问津,对话-人类：你无法完成其它任务,魅力沟通3,4：完成一项其它任务,沟通2,5：查看5门并进行理智检定,",
    "墓地：复苏,战斗-亡灵：场上有人死亡时令其复活并获得【亡灵化】【暗夜-饥渴】,体力劈刺2,1：成长【亡灵化：你无法成为射击的目标；你无法通过夜宴获得治疗。】腐化【暗夜-饥渴：亡灵化且造成伤害时获得治疗】,飞弹1,2：弃一张牌,",
    "会说话的书,对话-法阵：你无法攻击与被攻击。,魅力沟通3,3：完成一项其它任务,触摸/射击1,4：受到1点伤害,",
    "睿问法阵,对话-神孽：受到1点伤害,魅力表演2,1：查看任意数量门并进行理智检定,触摸/射击1,5：受到2点伤害,",
    "群山的恐怖,对话-神孽：弃一张牌,意志鹰眼2,3：成长【视野：智力+1】腐化【暮光-自毁：智力+1，缺陷：受到1点伤害】,开导1,2：弃一张牌,",
    "魔力乱流,事件-天象：所有人的触摸、护盾、飞弹、闪现只能用于推进该任务,护盾飞弹闪现2,4：抽一张牌,触摸1,5：所有人丢弃所有手牌,",
    "清凉的泉水,事件-地形：治疗1,体力沟通2,5：抽取等于智力的牌,沟通1,4：弃一张牌,",
    "苏醒,战斗-半神：所有人弃一张牌，否则受1点伤害,魅力沟通2,3：展示手牌，每有一张沟通抽一张牌,攻击1,2：受一点伤害,",
    "狩猎：逃吧，小虫子们,战斗-半神：直到你的下个回合开始前，这个任务无法被执行。,体力躲避：2,1：直到你的下个回合开始前，你无需领取任务。,攻击1,4：跳过你的下个回合,",
    "沉思：没有什么是真的,战斗-半神：每个人的回合结束时，展示手牌,智力飞弹2,2：抽两张牌,躲避1,3：所有人弃掉所有手牌,",
    "灰蕈洼地：谁想来一场华丽的冒险？,事件-地形：每个人回合结束时，抽一张牌，弃一张牌,智力飞弹1,5：成长【梦幻泡影：阻挡下一次伤害】,夜宴1,4：抽一张牌，受伤1,",
    "暮光教徒：加入我们,战斗-人类：回合开始时：抽一张牌。回合结束时，受到1点伤害,体力攻击2,1：成长【礼仪：手牌上限+2】腐化【暮光-仪式匕首：每次受伤抽一张牌】,夜宴1,2：受伤1,",
    "女王的邀请,对话-人类：所有人抽一张牌,魅力夜宴1,5：治疗1,任意1,2：所有人弃一张牌,",
    "逃兵拦路,战斗-人类：回合开始时受一点伤害,体力攻击2,3：成长【制式盔甲：阻挡下一次伤害】,任意2,4：受伤1,",
    "不死人突袭,战斗-亡灵：抽一张牌,体力防御2,2：抽两张牌,攻击1,4：受伤1,",
    "天降陨石,事件-天象：立即完成其它所有任务，分支随机,智力攻击3,2：所有人补满手牌,防御2,5：所有人受伤1,",
    "森林：迷失,事件-地形：回合结束时弃一张牌,智力观察2,2：抽两张牌,劈刺1,3：所有人弃一张牌,",
    "诡异迷雾,事件-天象：其它任务需求+1,智力飞弹1,1：额外获得线索2,触摸1,3：受伤1,",
    "天际流火,事件-天象：抽两张牌,智力观察3,5,攻击2,1,",
    "流浪药剂师,对话-人类：弃一张牌,魅力夜宴1,1：治疗1,攻击1,5：弃两张牌,",
    "会说话的书,对话-法阵：回合结束时，若可能，将它交给其他人,魅力沟通2,4：完成场上一个任务，分支任意,攻击1,3,",
    "复活的石像,对话-半神：回合开始时，展示你的弱点,魅力沟通2,5：查看场上一个人的弱点,沟通2,4：展示你的弱点,",
    "尘世巨蟒,战斗-半神：所有人进行一次理智检定,攻击防御1,,沟通观察1,,",
    "神秘腰带,事件-神器：回合结束时，若可能，转交给其它人。,男性角色,1：成长【变身女性：魅力+1】,攻击2,2：成长【变身女性：魅力-1】,",
    "神秘歌声,事件-半神：所有人弃掉沟通牌,女性角色,1：成长【变身男性：魅力+1】,沟通1,3：成长【变身男性：魅力-1】,",
    "遗忘秘仪,战斗-人类：回合开始时：弃掉所有攻击牌,意志观察2,4：成长【天之车轮：成为半神，不受其它更改种族的成长影响】腐化【暮光-神孽：成为半神，生命值等于手牌数量，受伤时弃掉相应数量手牌】,攻击1,5：弃掉所有手牌,",
    "意外爆炸,事件-人类：回合开始时，弃掉所有沟通牌,智力防御2,5：成长【神秘随从：回合开始时额外抽一张牌】腐化【暮光-背叛：受伤2】,攻击2,1：受伤2,",
    "思恋幻影,对话-心灵：回合开始时刷新任务代替这张牌。,其它角色的沟通1,3：逆转一张腐化,沟通1,1：弃一张牌,",
    "炉边酒馆,对话-人类,其它角色的沟通2,5：治疗1,沟通1,2：下个回合开始时不抽牌,",
    "贤狼夫妇,对话-半神：回合结束时：所有人弃一张牌,魅力沟通2,4：成长【商业知识：人类任务沟通难度-1】,攻击1,3：弃一张牌,",
    "审判官,战斗-教廷：回合开始时，所有人弃掉所有攻击牌,体力攻击2,3：抽一张牌,飞弹1,5：受伤2,",
    "无臂孕妇,对话-神秘,夜宴就医1,5,沟通1,1,",
    "暗夜教徒,战斗-人类：理智检定,体力沟通1,2：治疗1,攻击1,2,",
    "森林：小屋,战斗-神孽：回合结束时弃一张牌，否则受伤1,体力防御2,4：抽两张牌,沟通1,1：受伤1,",
    "山谷：木屋,战斗-神孽：所有人弃掉所有沟通牌,体力攻击2,5：抽两张牌,沟通1,3：受伤1,",
    "血肉交易,事件-法阵：抽两张牌,健康2,1：抽两张牌,攻击2,4：受伤1,",
    "一个游戏,对话-半神,回合开始时,2：治疗1,攻击1,5：受伤1,",
    "一个条件,对话-半神：你受到的所有伤害强制为1,体力攻击3,4：对场上任意一人造成1点伤害,回合开始时,2：受伤1,",
    "一个想法,对话-心灵：成长,其它角色的沟通1,2：抽一张牌，弃一张牌,回合开始时,3：理智检定,",
    "一套法律,战斗-教廷：抽一张牌；回合结束时弃一张牌,智力沟通3,4,行动3,4：受伤1,",
    "魔力饼干店,事件-人类：弃两张牌,智力触摸1,5：抽两张牌,沟通1,1,",
    ",,,,,,",
  ],
  "derivative": [
    "成长【亡灵化：你无法成为射击的目标；你无法通过夜宴获得治疗。】,腐化【暗夜-饥渴：亡灵化且造成伤害时获得治疗】",
    "成长【视野：智力+1】,腐化【暮光-自毁：智力+1，缺陷：受到1点伤害】",
    "成长【梦幻泡影：阻挡下一次伤害】,",
    "成长【礼仪：手牌上限+2】,腐化【暮光-仪式匕首：每次受伤抽一张牌】",
    "成长【制式盔甲：阻挡下一次伤害】,",
    "成长【变身女性：魅力+1】,",
    "成长【变身男性：魅力+1】,",
    "成长【天之车轮：成为半神，不受其它更改种族的成长影响】,腐化【暮光-神孽：成为半神，生命值等于手牌数量，受伤时弃掉相应数量手牌】",
    "成长【神秘随从：回合开始时额外抽一张牌】,腐化【暮光-背叛：受伤2】",
    "成长【商业知识：人类任务沟通难度-1】,",
  ],
};
export default cardData;