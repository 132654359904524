import cardData from "./cards/cards";
import { everyone, publicAreaNames, AllAreas, Message, MessageContent, Options, pileIsStacked, doorNames, PrivateAreas, Statuses, cardBackVisibility } from "./types";
import utils from "./utils";

class Card {
  flipped: boolean;
  tapped: boolean;
  front: string;
  back: string;
  constructor(front: string, back: string, flipped: boolean = false, tapped: boolean = false) {
    this.front = front;
    this.back = back;
    this.flipped = flipped;
    this.tapped = tapped;
  }
  flip() {
    this.flipped = !this.flipped;
  }
  tap() {
    this.tapped = !this.tapped;
  }
  toString(frontOnly: boolean = false): string {
    // flipped = true have higher priority than frontOnly = true
    let result = `front: ${this.front}`;
    if (!frontOnly || this.flipped) { result += `back: ${this.back}`; }
    if (this.flipped) { result += ` flipped`; }
    if (this.tapped) { result += ` tapped`; }
    return result;
  }
}

const dummyCard = new Card("no content", "no content");

class Pile {
  name: AllAreas;
  owner: number;
  cards: Array<Card> = [];
  constructor(clientName: AllAreas, owner: number, cards: Card[] = []) {
    this.name = clientName;
    this.owner = owner;
    this.cards = cards;
  }
  popFirst(): Card {
    const c = this.cards.shift();
    if (c === undefined) {
      return dummyCard;
    } else { return c };
  }
  randomScry(num: number): Card[] {
    return utils.choice(this.cards, num);
  }
  shuffle() {
    this.cards = utils.shuffle(this.cards);
  }
  push(c: Card): void {
    this.cards.push(c);
  }
  viewFirst(num: number = 1): Array<Card> {
    return this.cards.slice(0, num);
  }
  viewAll(): Array<Card> {
    return this.cards;
  }
  popIdx(idx: number): Card {
    let removed = this.cards.splice(idx, 1);
    if (removed.length === 1) {
      return removed[0];
    } else {
      return dummyCard;
    }
  }
  getLength(): number {
    return this.cards.length;
  }
  genUpdateMessage(target: number): Message {
    const result = { target: target, msg: {} as MessageContent } as Message;

    const isFrontOnly = !cardBackVisibility[this.name]

    if (pileIsStacked[this.name]) {
      // action, rot, quest
      // public piles
      // target should be everyone here
      result.msg.msgType = "pileCards";
      result.msg.pileCards = {
        pile: this.name,
        cards: this.viewFirst().map(c => c.toString(isFrontOnly)),
      };
    } else if (this.owner === everyone) {
      // discarded, battlefield, derivative, door
      // public piles
      // target should be everyone here
      result.msg.msgType = "pileCards";
      result.msg.pileCards = {
        pile: this.name,
        cards: this.viewAll().map(c => c.toString(isFrontOnly)),
      };
    } else if (target === this.owner) {
      // self owned piles
      result.msg.msgType = "pileCards";
      result.msg.pileCards = {
        pile: this.name,
        cards: this.viewAll().map(c => c.toString()),
      };
    } else if (target === everyone) {
      // other player owned piles
      result.msg.msgType = "characterPile";
      result.msg.characterPile = {
        id: this.owner,
        pile: this.name as PrivateAreas,
        cards: this.viewAll().map(c => c.toString(isFrontOnly)),
      };
    } else {
      // other player owned piles, send all info privately, mainly for scrying weakness
      result.msg.msgType = "characterPile";
      result.msg.characterPile = {
        id: this.owner,
        pile: this.name as PrivateAreas,
        cards: this.viewAll().map(c => c.toString()),
      };
    }

    return result;
  }
}

class Status {
  owner;
  name: Statuses;
  value: number;
  constructor(owner: number, name: Statuses, value: number) {
    this.owner = owner;
    this.name = name;
    this.value = value;
  }
  set(newVal: number) {
    this.value = newVal;
  }
  inc(delta: number) {
    this.value += delta;
  }
  genUpdateMessage(target: number): Message {
    const result = {
      target: target, msg: {
        msgType: "characterStatus",
        characterStatus: {
          id: this.owner,
          name: this.name,
          value: this.value,
        }

      } as MessageContent
    } as Message;
    return result;
  }
}

class Character {
  id; // not used
  name;
  description;
  health;
  weakness; quests; rots; hand;

  constructor(name: string, description: string, id: number) {
    this.name = name;
    this.description = description;
    this.id = id;
    this.health = new Status(this.id, "health", 3);
    this.weakness = new Pile("indivWeakness", this.id);
    this.quests = new Pile("indivQuest", this.id);
    this.rots = new Pile("indivRot", this.id);
    this.hand = new Pile("hand", this.id);
  }
}


type PublicAreas = Record<typeof publicAreaNames[number], Pile>;

class GameServer {
  characters: Array<Character> = [];
  publicAreas: PublicAreas = {} as PublicAreas;

  constructor() {
    for (const k of publicAreaNames) {
      this.publicAreas[k] = new Pile(k, everyone);
    }
    this.publicAreas.rot = new Pile("rot", everyone, cardData['rot'].map((row) => {
      return new Card(row.split(",").slice(0, 2).join(":"), row);
    }));
    this.publicAreas.derivative = new Pile("derivative", everyone, cardData['derivative'].map((row) => {
      return new Card(row.split(",")[0], row);
    }));
    this.publicAreas.door = new Pile("door", everyone, utils.shuffle([...doorNames]).map((n) => new Card("door", n)))
    const genCards = (raw: string[], front: string) => (raw.map(row => new Card(front, row)));
    const categories = ["action", "quest"] as const;
    for (const category of categories) {
      this.publicAreas[category] = new Pile(category, everyone, genCards(cardData[category], category));
    }

    for (const pn of publicAreaNames) {
      this.publicAreas[pn].cards = utils.shuffle(this.publicAreas[pn].cards);
    }
  }

  start(characterIds: number[]): Message[] {
    this.characters = characterIds.map((cid) => cardData.hero[cid]).map((h, idx) => {
      let statuses = h.split(",").slice(1, 5).map(i => parseInt(i));
      const c = new Character(h.split(",")[0], h.split(",").slice(1).join(","), idx + 1);
      if (statuses[0] > 0) {
        c.health.inc(1);
      } else if (statuses[0] < 0) {
        c.health.inc(-1);
      }
      return c;
    });


    const msgs = [0, 1, 2].map(() => ({ msg: {} as MessageContent } as Message));
    msgs[0].target = everyone;
    msgs[0].msg.msgType = "control";
    msgs[0].msg.control = "start";
    msgs[1].target = everyone;
    msgs[1].msg.msgType = "charactersInit";
    msgs[1].msg.characterInit = this.characters.map((c, idx) => ({
      id: idx + 1, name: c.name, description: c.description, health: c.health.value
    }));


    this.characters.forEach((c, idx) => {
      this.characters[idx].weakness.push(this.publicAreas.rot.popFirst());
      msgs.push(c.weakness.genUpdateMessage(everyone));
      msgs.push(c.weakness.genUpdateMessage(c.id));
    });

    for (const pn of publicAreaNames) {
      msgs.push(this.publicAreas[pn].genUpdateMessage(everyone));
    }
    return msgs;
  }

  receiveMsg(m: Message): Message[] {
    console.log(`receive message ${JSON.stringify(m)}`);
    if (m.msg.msgType !== "command") {
      return [];
    }

    const results: Message[] = [];

    const cid = m.source - 1;
    const splitted = m.msg.command.split(" ");
    const option = splitted[0] as Options;
    const targetName = splitted[1];
    const value = parseInt(splitted[2]);

    switch (option) {
      case "draw": {
        const area = targetName as AllAreas;
        switch (area) {
          case "action": {
            this.characters[cid].hand.push(this.publicAreas.action.popFirst());
            results.push(this.characters[cid].hand.genUpdateMessage(m.source));
            results.push(this.publicAreas.action.genUpdateMessage(everyone));
            results.push(this.characters[cid].hand.genUpdateMessage(everyone));
            break;
          }
          case "rot": {
            this.characters[cid].rots.push(this.publicAreas.rot.popFirst());
            results.push(this.characters[cid].rots.genUpdateMessage(m.source));
            results.push(this.publicAreas.rot.genUpdateMessage(everyone));
            results.push(this.characters[cid].rots.genUpdateMessage(everyone));
            break;
          }
          case "quest": {
            this.characters[cid].quests.push(this.publicAreas.quest.popFirst());
            results.push(this.characters[cid].quests.genUpdateMessage(m.source));
            results.push(this.publicAreas.quest.genUpdateMessage(everyone));
            results.push(this.characters[cid].quests.genUpdateMessage(everyone));
            break;
          }
          default: {
            console.log(`wrong combination of option and target name: ${option} ${targetName}`);
          }
        }
        results.push({
          target: everyone,
          msg: {
            msgType: "info", info: `player ${m.source} ${this.characters[cid].name} draw from ${targetName}`
          } as MessageContent
        } as Message);
        break;
      }
      case "scry": {
        const area = targetName as AllAreas;
        switch (area) {
          case "action": {
            results.push({
              target: m.source,
              msg: {
                msgType: "info", info: this.publicAreas.action.viewFirst(value).map(c => `${c.toString()} in ${targetName}`).join("\n")
              } as MessageContent
            } as Message);
            break;
          }
          case "rot": {
            results.push({
              target: m.source,
              msg: {
                msgType: "info", info: this.publicAreas.rot.viewFirst(value).map(c => `${c.toString()} in ${targetName}`).join("\n")
              } as MessageContent
            } as Message);
            break;
          }
          case "indivWeakness": {
            results.push({
              target: m.source,
              msg: {
                msgType: "info", info:
                  this.characters[value - 1].weakness.viewAll()
                    .map(c => c.toString()).map(s => `${s} in ${this.characters[value - 1].name} ${area}`).join("\n")
              } as MessageContent
            } as Message);
            results.push(this.characters[value - 1].weakness.genUpdateMessage(m.source));
            break;
          }
          case "hand": {
            // view hand of other player
            results.push({
              target: m.source,
              msg: {
                msgType: "info", info:
                  this.characters[value - 1].hand.viewAll()
                    .map(c => c.toString()).map(s => `${s} in ${this.characters[value - 1].name} ${area}`).join("\n")
              } as MessageContent
            } as Message);
            break;
          }
          case "door": {
            results.push({
              target: m.source,
              msg: {
                msgType: "info", info:
                  `${this.publicAreas.door.cards[value].toString()} in ${targetName}`
              } as MessageContent
            } as Message);
            break;
          }
        }
        results.push({
          target: everyone,
          msg: {
            msgType: "info", info: `player ${m.source} ${this.characters[cid].name} scry ${value} ${targetName}`
          } as MessageContent
        } as Message);
        break;
      }
      case "pick": {
        switch (targetName) {
          case "derivative": {
            this.characters[cid].rots.push(this.publicAreas.derivative.popIdx(value));
            results.push(this.characters[cid].rots.genUpdateMessage(m.source));
            results.push(this.publicAreas.derivative.genUpdateMessage(everyone));
            results.push(this.characters[cid].rots.genUpdateMessage(everyone));
            break;
          }
        }
        results.push({
          target: everyone,
          msg: {
            msgType: "info", info: `player ${m.source} ${this.characters[cid].name} pick ${value} from ${targetName}`
          } as MessageContent
        } as Message);
        break;
      }
      case "discard": {
        const area = targetName as AllAreas;
        switch (area) {
          case "battlefield": {
            this.publicAreas.discarded.push(this.publicAreas.battlefield.popIdx(value));
            results.push(this.publicAreas.discarded.genUpdateMessage(everyone));
            results.push(this.publicAreas.battlefield.genUpdateMessage(everyone));
            break;
          }
          case "hand": {
            this.publicAreas.discarded.push(this.characters[cid].hand.popIdx(value));
            results.push(this.characters[cid].hand.genUpdateMessage(m.source));
            results.push(this.publicAreas.discarded.genUpdateMessage(everyone));
            results.push(this.characters[cid].hand.genUpdateMessage(everyone));
            break;
          }
          case "indivRot": {
            this.publicAreas.discarded.push(this.characters[cid].rots.popIdx(value));
            results.push(this.characters[cid].rots.genUpdateMessage(m.source));
            results.push(this.publicAreas.discarded.genUpdateMessage(everyone));
            results.push(this.characters[cid].rots.genUpdateMessage(everyone));
            break;
          }
          case "indivQuest": {
            this.publicAreas.discarded.push(this.characters[cid].quests.popIdx(value));
            results.push(this.characters[cid].quests.genUpdateMessage(m.source));
            results.push(this.publicAreas.discarded.genUpdateMessage(everyone));
            results.push(this.characters[cid].quests.genUpdateMessage(everyone));
            break;
          }
        }
        results.push({
          target: everyone,
          msg: {
            msgType: "info", info: `player ${m.source} ${this.characters[cid].name} discard ${value} from ${targetName}`
          } as MessageContent
        } as Message);
        break;
      }
      case "use": {
        const area = targetName as AllAreas;
        switch (area) {
          case "hand": {
            this.publicAreas.battlefield.push(this.characters[cid].hand.popIdx(value));
            results.push(this.characters[cid].hand.genUpdateMessage(m.source));
            results.push(this.publicAreas.battlefield.genUpdateMessage(everyone));
            results.push(this.characters[cid].hand.genUpdateMessage(everyone));
            break;
          }
          case "indivWeakness": {
            this.characters[cid].rots.push(this.characters[cid].weakness.popIdx(value));
            results.push(this.characters[cid].rots.genUpdateMessage(m.source));
            results.push(this.characters[cid].weakness.genUpdateMessage(m.source));
            results.push(this.characters[cid].rots.genUpdateMessage(everyone));
            results.push(this.characters[cid].weakness.genUpdateMessage(everyone));
            break;
          }
        }
        results.push({
          target: everyone,
          msg: {
            msgType: "info", info: `player ${m.source} ${this.characters[cid].name} use ${value} from ${targetName}`
          } as MessageContent
        } as Message);
        break;
      }
      case "tap": {
        const area = targetName as AllAreas;
        switch (area) {
          case "indivQuest": {
            this.characters[cid].quests.cards[value].tap();
            results.push(this.characters[cid].quests.genUpdateMessage(m.source));
            results.push(this.characters[cid].quests.genUpdateMessage(everyone));
            break;
          }
        }
        results.push({
          target: everyone,
          msg: {
            msgType: "info", info: `player ${m.source} ${this.characters[cid].name} tap ${value} of ${targetName}`
          } as MessageContent
        } as Message);
        break;
      }
      case "flip": {
        const area = targetName as AllAreas;
        switch (area) {
          case "indivRot": {
            this.characters[cid].rots.cards[value].flip();
            results.push(this.characters[cid].rots.genUpdateMessage(m.source));
            results.push(this.characters[cid].rots.genUpdateMessage(everyone));
            break;
          }
          // cancel door.flip for security.
          //   case "door": {
          //     this.publicAreas.door.cards[value].flip();
          //     results.push({
          //       target: m.source,
          //       msg: {
          //         msgType: "info", info:
          //           `${this.publicAreas.door.cards[value].toString()} in ${targetName}`
          //       } as MessageContent
          //     } as Message);
          //     results.push(this.publicAreas.door.genUpdateMessage(m.source));
          //     break;
          //   }
        }
        results.push({
          target: everyone,
          msg: {
            msgType: "info", info: `player ${m.source} ${this.characters[cid].name} flip ${value} of ${targetName}`
          } as MessageContent
        } as Message);
        break;
      }
      case "increase": {
        const status = targetName as Statuses;
        switch (status) {
          case "health": {
            this.characters[cid].health.inc(value);
            results.push(this.characters[cid].health.genUpdateMessage(everyone));
            break;
          }
        }
        results.push({
          target: everyone,
          msg: {
            msgType: "info", info: `player ${m.source} ${this.characters[cid].name} change ${targetName} ${value}`
          } as MessageContent
        } as Message);
        break;
      }
    }
    return results;
  }

}

export default GameServer;
