function shuffle<T>(array: T[]) {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

function choice<T>(arr: Array<T>, num: number) {
  let choices: number[] = [];
  if (num <= 0 || num > arr.length) {
    return [];
  }
  while (choices.length < num) {
    let val = Math.floor(Math.random() * arr.length)
    if (choices.includes(val)) continue;
    choices.push(val);
  }
  return choices.map((val) => arr[val]);
}

const _ = { shuffle, choice };
export default _; 
