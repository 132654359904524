import React from "react";
import GameServer from "./GameServer";

function ServerDisplay({ children, gameServer, ws }: React.PropsWithChildren<{ gameServer: React.MutableRefObject<GameServer>, ws: React.MutableRefObject<WebSocket | null> }>) {
  return (
    <>
      {children}
      <button type="button" onClick={() => {
        gameServer.current.start(prompt("input character ids, separated by comma")!.split(",").map(s => parseInt(s))).forEach(msg => {
          console.log(`sending ${JSON.stringify(msg)}`);
          ws.current!.send(JSON.stringify(msg));
          return;
        })
      }}>start</button>
    </>
  );
}
export default ServerDisplay;
